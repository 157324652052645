<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>

        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <div v-if="is_API_loaded" class="space-y-10">
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">收款方式設定</h3>
            <p>設定您的銀行轉帳資訊，供顧客選擇。</p>
          </div>
          <div class="rightBlock">
            <div class="space-y-6">
              <div class="flex items-center justify-between">
                <h3 class="h3">是否開啟此收款方式</h3>
                <vs-switch color="warning" v-model="setting.bank_isenable" />
              </div>

              <div>
                <div class="flex items-center justify-between mb-1">
                  <h4 class="h4">付款說明</h4>
                </div>
                <ValidationProvider tag="div" class="w-full relative" name="付款說明" rules="max:500" v-slot="{ errors }">
                  <myTextarea v-model="setting.bank_bname" maxlength="500" placeholder="在此輸入您的收款銀行帳號資訊和說明。"></myTextarea>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <div class="flex items-center justify-between">
          <router-link :to="{ name: 'store_payments' }">
            <vs-button border size="large">取消 </vs-button>
          </router-link>
          <vs-button size="large" ref="saveBtn" @click="saveEdit">儲存</vs-button>
        </div>
      </div>

      <!-- 骨架屏 -->
      <div class="space-y-4" v-else>
        <section class="wrap">
          <div class="leftBlock">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="rightBlock">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
              <skeleton width="100px" />
            </div>
            <div class="flex items-start flex-col space-y-4">
              <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bankTransfer',
  data() {
    return {
      is_API_loaded: false,
      setting: {},
    }
  },
  created() {
    this.loadSetting()
  },
  methods: {
    // 4.4.1.1 金流資料-讀取
    loadSetting() {
      this.$axios({
        url: 'front/set/app/getGoldData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          console.log('金流資料-讀取', res)

          let data = res.data.Data

          data.bank_isenable = data.bank_isenable === 1 ? true : false

          this.setting = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.4.1.4 金流第三方資料-修改
    saveEdit() {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/app/uGoldData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          bank_isenable: this.setting.bank_isenable ? 1 : 0,
          bank_bname: this.setting.bank_bname,
          // bank_bcode: this.setting.bank_bcode,
          // bank_uname: this.setting.bank_uname,
          // bank_account: this.setting.bank_account,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          // this.loadSetting()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}
</style>
